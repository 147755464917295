import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import ImageHeader from "components/ui/base/ImageHeader";
import { useTranslation } from "react-i18next";
import TestimonialWithImageIrene from "components/ui/extended/TestimonialWithImageIrene";
import ImageWithDescription from "components/ui/base/ImageWithDescription";
import VideoLiveChatBox from "components/ui/extended/VideoLiveChatBox";
import VideoPreview from "components/ui/base/VideoPreview";
import MobileBox from "components/ui/extended/MobileBox";
import RegisterForEcommerce from "components/ui/extended/RegisterForEcommerce";
import Customers from "components/ui/extended/Customers";
import InstallLiveChat from "components/ui/extended/InstallLiveChat";
import ConversationInboxEcommerceTabs from "components/ui/extended/ConversationInboxEcommerceTabs";
import ReadPost from "components/ui/base/ReadPost";
import CustomerSupportFeatures from "components/ui/extended/CustomerSupportFeatures";

interface EcommerceProps {
  location: Location;
}

export default ({ location }: EcommerceProps) => {
  const { t } = useTranslation();
  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.industries.ecommerce.seo.title")}
        description={t("pages.industries.ecommerce.seo.description")}
        image={{
          relativePath: "meta/live-chat-for-ecommerce.jpg",
          alt: "Live Chat for eCommerce ",
        }}
      />

      <ImageHeader
        title={t("pages.industries.ecommerce.title")}
        description={t("pages.industries.ecommerce.description")}
        cta={{ show: true }}
        image={{
          relativePath:
            "pages/industries/ecommerce/live-chat-for-ecommerce-shopify-woocommerce-magento-prestashop.jpg",
          alt: "Ecommerce",
        }}
      />

      <ReadPost slug={"live-chat-features"} />

      <TestimonialWithImageIrene />

      <ConversationInboxEcommerceTabs />

      <ImageWithDescription
        title={t("pages.industries.ecommerce.autoreply.title")}
        description={t("pages.industries.ecommerce.autoreply.description")}
        cta={{
          link: "/help-center/",
          text: t("pages.industries.ecommerce.autoreply.cta"),
        }}
        image={{
          relativePath:
            "pages/industries/ecommerce/live-chat-ecommerce-bot-autoreply.jpg",
          alt: t("pages.industries.ecommerce.autoreply.title"),
          fullWidth: true,
          position: "right",
        }}
      />

      <VideoLiveChatBox />

      <ImageWithDescription
        title={t("pages.industries.ecommerce.customize.title")}
        description={t("pages.industries.ecommerce.customize.description")}
        image={{
          relativePath:
            "pages/industries/ecommerce/live-chat-customize-look-and-feel.jpg",
          alt: t("pages.industries.ecommerce.customize.title"),
          fullWidth: true,
          position: "left",
        }}
      />

      <VideoPreview
        title={t("pages.industries.ecommerce.welcome.title")}
        paragraph={t("pages.industries.ecommerce.welcome.paragraph")}
        storiesPosition={"right"}
        stories={[
          {
            relativePath:
              "pages/live-chat-engagement/live-chat-welcome-message.mov",
          },
        ]}
      />

      <MobileBox />

      <InstallLiveChat />

      <RegisterForEcommerce />

      <CustomerSupportFeatures />

      <Customers />
    </GlobalWrapper>
  );
};
